"use client";

import { useTranslations } from "next-intl";
import Marquee from "@/components/ui/marquee";
import { DummyTopRestaurants } from "@/constants";
import { ChefHat, Coffee, Drumstick, Fish, UtensilsCrossed, Wine } from "lucide-react";
import { Link } from "@nextui-org/react";
import { cn } from "@/lib/utils";

const RestaurantsNames = () => {
  const t = useTranslations("restaurants");

  const renderIcon = (index: number) => {
    const icons = [
      { Icon: Coffee, color: "text-amber-500" },
      { Icon: UtensilsCrossed, color: "text-purple-500" },
      { Icon: ChefHat, color: "text-blue-500" },
      { Icon: Fish, color: "text-emerald-500" },
      { Icon: Wine, color: "text-rose-500" },
      { Icon: Drumstick, color: "text-orange-500" }
    ];
    
    const { Icon, color } = icons[index % icons.length];
    
    return (
      <div className="relative group">
        <Icon className={cn(
          "w-8 h-8 mr-3 transition-all duration-300",
          "opacity-75 group-hover:opacity-100",
          "group-hover:scale-110",
          color
        )} />
        <div className={cn(
          "absolute inset-0 blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-300",
          color
        )} />
      </div>
    );
  };

  const RestaurantItem = ({ restaurant, index, isReverse = false }: any) => (
    <Link 
      href={restaurant.douq_ma_profile || ""} 
      className={cn(
        "group relative flex items-center",
        "mx-8 transition-all duration-300",
        "hover:scale-105"
      )}
    >
      <div className={cn(
        "absolute inset-0 rounded-2xl opacity-0 group-hover:opacity-5",
        "bg-gradient-to-r from-primary-brand to-transparent",
        "transition-opacity duration-300"
      )} />
      
      <h2 className={cn(
        "text-3xl md:text-4xl font-extrabold",
        "text-primary-brand dark:text-muted-brand",
        "opacity-85 group-hover:opacity-100",
        "flex items-center",
        "transition-all duration-300",
        "group-hover:tracking-wide"
      )}>
        {renderIcon(index)}
        <span className="relative">
          {t(restaurant.name)}
          <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-primary-brand group-hover:w-full transition-all duration-300" />
        </span>
        <span className="mx-4 opacity-25 text-4xl">•</span>
      </h2>
    </Link>
  );

  return (
    <div className="relative w-full">
      {/* Gradient Overlays */}
      <div className="absolute left-0 top-0 bottom-0 w-24 bg-gradient-to-r from-background to-transparent z-10" />
      <div className="absolute right-0 top-0 bottom-0 w-24 bg-gradient-to-l from-background to-transparent z-10" />

      <div className="mx-auto max-w-[100vw] py-16 overflow-hidden">
        {/* First Marquee */}
        <div className="relative mb-12">
          <Marquee className="[--duration:40s] py-4">
            {DummyTopRestaurants.map((restaurant, index) => (
              <RestaurantItem 
                key={`${restaurant.name}-1`}
                restaurant={restaurant} 
                index={index}
              />
            ))}
          </Marquee>
        </div>

        {/* Second Marquee */}
        <div className="relative">
          <Marquee reverse className="[--duration:35s] py-4">
            {DummyTopRestaurants.map((restaurant, index) => (
              <RestaurantItem 
                key={`${restaurant.name}-2`}
                restaurant={restaurant} 
                index={index + 3} 
                isReverse
              />
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default RestaurantsNames;