export const DummyRestaurants = [
  {
    name: "Yolan",
    rate: 4.8,
    city: "Nashville",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Merois",
    rate: 4.6,
    city: "Los Angeles",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Camphor",
    rate: 4.7,
    city: "Los Angeles",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Providence",
    rate: 4.8,
    city: "Los Angeles",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Aldama",
    rate: 4.5,
    city: "Brooklyn",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Atelier Crenn",
    rate: 4.9,
    city: "San Francisco",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Le Bernardin",
    rate: 4.8,
    city: "New York City",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Locust",
    rate: 4.6,
    city: "Nashville",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Naro",
    rate: 4.7,
    city: "New York City",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Leah and Louise",
    rate: 4.7,
    city: "Charlotte",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "March",
    rate: 4.8,
    city: "Houston",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Maty's",
    rate: 4.6,
    city: "Miami",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Kin",
    rate: 4.7,
    city: "Boise",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Nixta Taqueria",
    rate: 4.8,
    city: "Austin",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Mister Jiu's",
    rate: 4.7,
    city: "San Francisco",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Moon Rabbit",
    rate: 4.5,
    city: "Washington D.C.",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Zahav",
    rate: 4.9,
    city: "Philadelphia",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "The Grey",
    rate: 4.6,
    city: "Savannah",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Canlis",
    rate: 4.8,
    city: "Seattle",
    image: "/assets/restaurant.jpg",
  },
  {
    name: "Pineapple and Pearls",
    rate: 4.7,
    city: "Washington D.C.",
    image: "/assets/restaurant.jpg",
  },
];

export const DummyTopRestaurants = [
  {
    name: "Otto Marrakech",
    douq_ma_profile: "https://douq.ma/place/otto-marrakech",
  },
  {
    name: "Dar Tajine Agadir",
    douq_ma_profile: "https://douq.ma/place/dar-tajine",
  },
  {
    name: "Chez Lamine Marrakech",
    douq_ma_profile: "https://douq.ma/place/chez-lamine",
  },
  {
    name: "West 91",
    douq_ma_profile: null,
  },
  {
    name: "Sunshine Restaurant Coffee",
    douq_ma_profile: null,
  },
  {
    name: "Restaurant Blacksmith Agadir",
    douq_ma_profile: null,
  },
  {
    name: "Pachamama El Jadida",
    douq_ma_profile: null,
  },
  {
    name: "Chez Lamine Hadj Mustapha",
    douq_ma_profile: null,
  },
  {
    name: "Le Pain Quotidien Casablanca",
    douq_ma_profile: null,
  },
  {
    name: "Restaurant Alf Lila Ou Lila",
    douq_ma_profile: null,
  },
];

export const regions = [
  {
    id: "1",
    region: "Tanger-Tétouan-Al Hoceïma",
  },
  {
    id: "2",
    region: "l'Oriental",
  },
  {
    id: "3",
    region: "Fès-Meknès",
  },
  {
    id: "4",
    region: "Rabat-Salé-Kénitra",
  },
  {
    id: "5",
    region: "Béni Mellal-Khénifra",
  },
  {
    id: "6",
    region: "Casablanca-Settat",
  },
  {
    id: "7",
    region: "Marrakech-Safi",
  },
  {
    id: "8",
    region: "Drâa-Tafilalet",
  },
  {
    id: "9",
    region: "Souss-Massa",
  },
  {
    id: "10",
    region: "Guelmim-Oued Noun",
  },
  {
    id: "11",
    region: "Laâyoune-Sakia El Hamra",
  },
  {
    id: "12",
    region: "Dakhla-Oued Ed Dahab",
  },
];

export const NavigationLinks = [
  { label: "link1", href: "/" },
  { label: "link2", href: "/services" },
  { label: "link3", href: "/contact" },
  { label: "link4", href: "/faq" },
];
