"use client"
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import {
  Search,
  MessageSquare,
  Sparkles,
  Calendar,
  Building2,
  Users,
  ShieldCheck,
  ChevronRight,
  ExternalLink
} from 'lucide-react';

const services = [
  {
    icon: Search,
    title: "Découverte de Restaurants",
    description: "Trouvez les meilleurs restaurants et cafés marocains dans votre ville.",
    details: "Utilisez nos filtres avancés pour trouver exactement ce que vous cherchez: cuisine, prix, ambiance, et plus encore.",
    gradient: "from-blue-500/20 to-blue-500/10",
    link: "/search"
  },
  {
    icon: MessageSquare,
    title: "Avis Utilisateurs",
    description: "Lisez et partagez des avis authentiques pour aider notre communauté.",
    details: "Des avis vérifiés de vrais clients, avec photos et notes détaillées pour une expérience transparente.",
    gradient: "from-purple-500/20 to-purple-500/10",
    link: "/reviews"
  },
  {
    icon: Sparkles,
    title: "Recommandations Personnalisées",
    description: "Recevez des suggestions adaptées à vos préférences et goûts.",
    details: "Notre algorithme apprend de vos préférences pour vous suggérer les meilleures adresses.",
    gradient: "from-amber-500/20 to-amber-500/10",
    link: "/recommendations"
  },
  {
    icon: Calendar,
    title: "Événements & Ateliers",
    description: "Découvrez les festivals culinaires et ateliers gastronomiques.",
    details: "Restez informé des événements gastronomiques, cours de cuisine et dégustations.",
    gradient: "from-green-500/20 to-green-500/10",
    link: "/events"
  },
  {
    icon: Building2,
    title: "Inscription d'Entreprises",
    description: "Les restaurateurs peuvent inscrire leur établissement pour plus de visibilité.",
    details: "Une suite complète d'outils pour gérer votre présence en ligne et attirer plus de clients.",
    gradient: "from-pink-500/20 to-pink-500/10",
    link: "/business"
  },
  {
    icon: Users,
    title: "Communauté",
    description: "Connectez-vous avec d'autres passionnés de gastronomie.",
    details: "Partagez vos découvertes, suivez d'autres gourmets et créez votre réseau foodie.",
    gradient: "from-indigo-500/20 to-indigo-500/10",
    link: "/community"
  },
  {
    icon: ShieldCheck,
    title: "Informations Vérifiées",
    description: "Accédez à des informations précises et à jour sur les établissements.",
    details: "Chaque établissement est vérifié par notre équipe pour garantir des informations fiables.",
    gradient: "from-teal-500/20 to-teal-500/10",
    link: "/verified"
  }
];

const Services = () => {
  const [expandedId, setExpandedId] = useState<number | null>(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <section className="mx-auto max-w-7xl px-4 py-16">
      <motion.div 
        className="text-center space-y-4 mb-12"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h2 className="text-3xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/80">
          Nos Services
        </h2>
        <p className="max-w-3xl mx-auto text-lg text-muted-foreground">
          Découvrez le meilleur de la cuisine marocaine avec Douq. Nous offrons
          une gamme de services pour améliorer votre expérience culinaire et
          vous connecter aux meilleurs restaurants et cafés du Maroc.
        </p>
      </motion.div>

      <motion.div 
        className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {services.map((service, index) => {
          const isExpanded = expandedId === index;
          const Icon = service.icon;

          return (
            <motion.div
              key={service.title}
              variants={itemVariants}
              className="h-full"
              layout
            >
              <Card 
                className={cn(
                  "h-full overflow-hidden border-none transition-all duration-300",
                  "bg-gradient-to-b from-white to-gray-50/50",
                  "dark:from-gray-900 dark:to-gray-900/50",
                  "hover:shadow-lg",
                  isExpanded ? "shadow-xl" : "shadow-md"
                )}
                onClick={() => setExpandedId(isExpanded ? null : index)}
              >
                <div className={cn(
                  "h-2 w-full bg-gradient-to-r",
                  service.gradient
                )} />
                
                <CardHeader>
                  <div className="mb-6 p-3 rounded-xl bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 w-fit">
                    <Icon className="w-6 h-6 text-primary" />
                  </div>
                  <CardTitle className="text-xl mb-2">
                    {service.title}
                  </CardTitle>
                  <CardDescription className="text-sm text-muted-foreground">
                    {service.description}
                  </CardDescription>
                </CardHeader>

                <AnimatePresence>
                  {isExpanded && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardContent className="pb-4">
                        <p className="text-sm text-muted-foreground mb-4">
                          {service.details}
                        </p>
                        <a 
                          href={service.link}
                          className={cn(
                            "inline-flex items-center gap-2 text-sm",
                            "text-primary hover:text-primary/80",
                            "transition-colors duration-200"
                          )}
                        >
                          En savoir plus
                          <ExternalLink className="w-4 h-4" />
                        </a>
                      </CardContent>
                    </motion.div>
                  )}
                </AnimatePresence>

                <Button
                  variant="ghost"
                  size="sm"
                  className="absolute top-4 right-4"
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandedId(isExpanded ? null : index);
                  }}
                >
                  <motion.div
                    animate={{ rotate: isExpanded ? 90 : 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <ChevronRight className="w-4 h-4" />
                  </motion.div>
                </Button>
              </Card>
            </motion.div>
          );
        })}
      </motion.div>
    </section>
  );
};

export default Services;