"use client"
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter
} from "@/components/ui/card";
import {
  Tabs,
  TabsList,
  TabsTrigger
} from "@/components/ui/tabs";
import {
  MapPin,
  ThumbsUp,
  Phone,
  Globe,
  TrendingUp,
  Star,
  Heart,
  CheckCircle2
} from 'lucide-react';
import { cn } from "@/lib/utils";
import { Place } from '@/types';
import StarRating from '../place/[id]/_components/star-rating';
import { fetchPlaces } from '../../../../../api/places';

const CATEGORIES = {
  trending: {
    label: "Tendances",
    icon: <TrendingUp className="w-4 h-4" />,
    params: { min_rating: 4 }
  },
  rated: {
    label: "Mieux notés",
    icon: <Star className="w-4 h-4" />,
    params: { min_rating: 4.5 }
  },
  popular: {
    label: "Populaires",
    icon: <ThumbsUp className="w-4 h-4" />,
    params: { min_rating: 4, has_website: true }
  }
};

const TopRestaurants = () => {
  const [activeCategory, setActiveCategory] = useState('rated');
  const [places, setPlaces] = useState<Place[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  
  useEffect(() => {
    const loadPlaces = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetchPlaces({
          ...CATEGORIES[activeCategory as keyof typeof CATEGORIES].params,
          per_page: 8,
          place_type: 'restaurant'
        });
        if (response?.places) {
          setPlaces(response.places);
        } else {
          setPlaces([]);
        }
      } catch (err) {
        setError('Une erreur est survenue lors du chargement des restaurants');
        console.error('Error fetching places:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadPlaces();
  }, [activeCategory]);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  const renderRestaurantCard = (place: Place) => (
    <motion.div
      key={place.id}
      variants={itemVariants}
      layout
    >
      <Card className="group h-full flex flex-col transition-shadow hover:shadow-lg">
        <CardHeader className="p-0">
          <div className="relative aspect-[4/3] overflow-hidden bg-muted">
            {place.image_url ? (
              <img
                src={place.image_url}
                alt={place.image_alt || place.name}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                loading="lazy"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-primary/10">
                <Globe className="w-12 h-12 text-primary/40" />
              </div>
            )}

            <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent" />

            {/* Status badges */}
            <div className="absolute top-2 left-2 flex flex-wrap gap-2 z-10">
              {place.verified && (
                <span className="px-2 py-1 rounded-full text-xs font-medium bg-primary/90 text-white backdrop-blur-sm">
                  <CheckCircle2 className="w-3 h-3 inline mr-1" />
                  Vérifié
                </span>
              )}
              {place.is_open_now && (
                <span className="px-2 py-1 rounded-full text-xs font-medium bg-green-500/90 text-white backdrop-blur-sm">
                  Ouvert
                </span>
              )}
            </div>

            <button
              className="absolute top-2 right-2 p-2 rounded-full bg-white/90 hover:bg-white transition-colors z-10"
              aria-label="Ajouter aux favoris"
            >
              <Heart className="w-4 h-4 text-primary hover:text-red-500 transition-colors" />
            </button>
          </div>
        </CardHeader>

        <CardContent className="flex-grow p-4">
          <div className="space-y-3">
            <div className="flex items-start justify-between gap-2">
              <h3 className="font-semibold text-base sm:text-lg leading-tight">
                {place.name}
              </h3>
              <StarRating rating={place.rating || 0} />
            </div>

            {place.description && (
              <p className="text-sm text-muted-foreground line-clamp-2">
                {place.description}
              </p>
            )}

            <div className="space-y-2 text-sm text-muted-foreground">
              <div className="flex items-center gap-2">
                <MapPin className="w-4 h-4 flex-shrink-0" />
                <span className="truncate">
                  {place.address || `${place.city}, ${place.country}`}
                </span>
              </div>

              {place.phone_number && (
                <div className="flex items-center gap-2">
                  <Phone className="w-4 h-4 flex-shrink-0" />
                  <span>{place.phone_number}</span>
                </div>
              )}
            </div>
          </div>
        </CardContent>

        <CardFooter className="p-4 pt-0 border-t">
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-2">
              <ThumbsUp className="w-4 h-4 text-primary" />
              <span className="text-sm font-medium">{place.votes_count}</span>
            </div>
            <a
              href={`/place/${place.slug}`}
              className="px-3 py-1.5 text-sm font-medium rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors"
            >
              Découvrir
            </a>
          </div>
        </CardFooter>
      </Card>
    </motion.div>
  );

  return (
    <section className="mx-auto max-w-7xl px-4 py-16">
      <div className="text-center space-y-4 mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-primary">
          Les Meilleures Adresses sur Douq
        </h2>
        <p className="max-w-2xl mx-auto text-lg text-muted-foreground">
          Découvrez les restaurants les plus appréciés à travers le Maroc,
          sélectionnés par notre communauté pour leurs expériences exceptionnelles.
        </p>
      </div>

      <Tabs
        defaultValue="trending"
        className="mb-8"
        onValueChange={setActiveCategory}
      >
        <TabsList className="mx-auto">
          {Object.entries(CATEGORIES).map(([key, { label, icon }]) => (
            <TabsTrigger
              key={key}
              value={key}
              className="flex items-center gap-2"
            >
              {icon}
              {label}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>

      {error ? (
        <div className="text-center py-12 text-muted-foreground">
          <p>{error}</p>
        </div>
      ) : (
        <motion.div
          className={cn(
            "grid gap-4 sm:gap-6 md:gap-8",
            "grid-cols-1",
            "sm:grid-cols-2",
            "lg:grid-cols-3",
            "xl:grid-cols-4",
            // Container query classes for better control
            "container-type-inline-size",
            "container-style-normal"
          )}
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          {isLoading ? (
            Array.from({ length: 8 }).map((_, i) => (
              <Card key={i} className="overflow-hidden">
                <div className="aspect-[4/3] bg-muted animate-pulse" />
                <CardContent className="p-4 space-y-3">
                  <div className="h-6 bg-muted rounded animate-pulse" />
                  <div className="h-4 bg-muted rounded animate-pulse w-2/3" />
                </CardContent>
              </Card>
            ))
          ) : (
            places.map(place => renderRestaurantCard(place))
          )}
        </motion.div>
      )}
    </section>
  );
};

export default TopRestaurants;