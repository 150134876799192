"use client"
import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/navigation';
import {
  Search, MapPin, Filter, ChevronDown, Star,
  Clock, Users, Coffee, Utensils, HeartHandshake,
  Sun, Moon, Sparkles, X, Loader2, ArrowRight,
  Crosshair
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogHeader, DialogFooter } from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { 
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
  SheetTrigger,
} from "@/components/ui/sheet";
import { 
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList
} from "@/components/ui/command";
import { 
  Popover, 
  PopoverContent, 
  PopoverTrigger 
} from "@/components/ui/popover";
import { useLocation } from '@/hooks/use-location';

const FILTERS = {
  placeType: [
    { id: 'restaurant', icon: <Utensils className="w-4 h-4" />, label: 'Restaurant' },
    { id: 'cafe', icon: <Coffee className="w-4 h-4" />, label: 'Cafe' },
  ],
  mealTime: [
    { id: 'breakfast', icon: <Sun className="w-4 h-4" />, label: 'Breakfast' },
    { id: 'lunch', icon: <Utensils className="w-4 h-4" />, label: 'Lunch' },
    { id: 'dinner', icon: <Moon className="w-4 h-4" />, label: 'Dinner' },
    { id: 'brunch', icon: <Coffee className="w-4 h-4" />, label: 'Brunch' }
  ],
  occasion: [
    { id: 'date', icon: <HeartHandshake className="w-4 h-4" />, label: 'Date Night' },
    { id: 'family', icon: <Users className="w-4 h-4" />, label: 'Family Friendly' },
  ],
  status: [
    { id: 'open', icon: <Clock className="w-4 h-4" />, label: 'Open Now' },
  ],
  sort: [
    { id: 'rating', icon: <Star className="w-4 h-4" />, label: 'Top Rated' },
    { id: 'popular', icon: <Sparkles className="w-4 h-4" />, label: 'Popular' },
  ]
} as const;

interface City {
  id: string;
  name: string;
  slug: string;
}

export default function DouqSearchBar() {
  const router = useRouter();
  const [showFilters, setShowFilters] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [search, setSearch] = useState('');
  const [location, setLocation] = useState('');
  const [cities, setCities] = useState<City[]>([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [isSearching, setIsSearching] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const { getLocation, isLoadingLocation, location: userLocation, cityName } = useLocation();

  useEffect(() => {
    const fetchCities = async () => {
      setIsLoadingCities(true);
      try {
        const response = await fetch('https://infra.douq.ma/api/v1/cities');
        const data = await response.json();
        const citiesWithSlugs = data.map((city: any) => ({
          ...city,
          slug: city.name.toLowerCase().replace(/\s+/g, '-')
        }));
        setCities(citiesWithSlugs);
      } catch (error) {
        console.error('Error fetching cities:', error);
      } finally {
        setIsLoadingCities(false);
      }
    };

    fetchCities();
  }, []);

  const handleSearch = async () => {
    if (!location) return;
    
    setIsSearching(true);
    const params = new URLSearchParams();
    if (search) params.append('q', search);
    if (location) params.append('location', location);
    
    Object.entries(selectedFilters).forEach(([key, value]) => {
      if (value) params.append(key, value);
    });

    if (userLocation) {
      params.append('lat', userLocation.latitude.toString());
      params.append('lon', userLocation.longitude.toString());
    }

    try {
      await router.push(`/search?${params.toString()}`);
    } finally {
      setIsSearching(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && location) {
      handleSearch();
    }
  };

  const handleLocationSearch = async () => {
    await getLocation();
    if (cityName) {
      const citySlug = cityName.toLowerCase().replace(/\s+/g, '-');
      const matchedCity = cities.find(city => city.slug === citySlug);
      if (matchedCity) {
        setLocation(matchedCity.name);
      } else {
        setLocation(cityName);
      }
      setShowCities(false);
    }
  };

  const getActiveFiltersCount = () => {
    return Object.values(selectedFilters).filter(Boolean).length;
  };

  return (
    <div className="w-full bg-transparent">
      <div className="bg-background rounded-2xl md:shadow-lg md:border md:border-border/40 p-4 md:p-6 space-y-4 md:space-y-6">
        {/* Location Search */}
        <div className="relative" ref={searchRef}>
          <Popover open={showCities} onOpenChange={setShowCities}>
            <PopoverTrigger asChild>
              <div className="relative">
                <MapPin className="absolute left-4 top-1/2 -translate-y-1/2 text-muted-foreground w-5 h-5" />
                <Input
                  type="text"
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                    setShowCities(true);
                  }}
                  onKeyPress={handleKeyPress}
                  placeholder="Select your city or area *"
                  className="pl-12 pr-12 h-14 md:h-12 text-base rounded-2xl md:rounded-xl border-border/50"
                />
                {location && (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      setLocation('');
                    }}
                    className="absolute right-2 top-1/2 -translate-y-1/2 h-10 w-10 hover:bg-accent rounded-xl"
                  >
                    <X className="h-5 w-5" />
                  </Button>
                )}
              </div>
            </PopoverTrigger>
            <PopoverContent 
              className="w-[var(--radix-popover-trigger-width)] p-0" 
              align="start"
              side="bottom"
              sideOffset={4}
            >
              <Command className="rounded-xl border shadow-md">
                <CommandList>
                  <CommandGroup>
                    <CommandItem
                      onSelect={handleLocationSearch}
                      className="flex items-center gap-3 py-4"
                    >
                      <Crosshair className="w-5 h-5 text-muted-foreground" />
                      <span className="text-base">Use my current location</span>
                    </CommandItem>
                    {isLoadingCities || isLoadingLocation ? (
                      <CommandItem disabled className="h-14">
                        <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                        <span className="text-base">Loading...</span>
                      </CommandItem>
                    ) : cities
                      .filter(city => 
                        city.name.toLowerCase().includes(location.toLowerCase())
                      )
                      .map(city => (
                        <CommandItem
                          key={city.id}
                          onSelect={() => {
                            setLocation(city.name);
                            setShowCities(false);
                          }}
                          className="flex items-center gap-3 py-4"
                        >
                          <MapPin className="w-5 h-5 text-muted-foreground" />
                          <span className="text-base">{city.name}</span>
                        </CommandItem>
                      ))}
                  </CommandGroup>
                  <CommandEmpty>
                    <div className="flex flex-col items-center py-6 text-muted-foreground">
                      <span className="text-base">No cities found</span>
                    </div>
                  </CommandEmpty>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        {/* Search Input */}
        <div className="relative">
          <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-muted-foreground w-5 h-5" />
          <Input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Restaurant name or cuisine"
            className="pl-12 h-14 md:h-12 text-base rounded-2xl md:rounded-xl border-border/50"
          />
        </div>

        {/* Filters Section - Mobile */}
        <div className="border-t pt-4 md:hidden">
          <Button
            variant="outline"
            onClick={() => setShowFilters(true)}
            className="w-full h-14 rounded-2xl flex items-center justify-between px-4 text-base"
          >
            <div className="flex items-center gap-2">
              <Filter className="w-5 h-5" />
              <span>Filters</span>
              {getActiveFiltersCount() > 0 && (
                <Badge variant="secondary" className="ml-1">
                  {getActiveFiltersCount()}
                </Badge>
              )}
            </div>
            <ChevronDown className="w-5 h-5" />
          </Button>
        </div>

        {/* Filters Section - Desktop */}
        <div className="hidden md:block border-t pt-4">
          <ScrollArea className="w-full pb-4">
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                onClick={() => setShowFilters(true)}
                className="h-11 px-4 rounded-xl flex items-center gap-2"
              >
                <Filter className="w-4 h-4" />
                <span>Filters</span>
                {getActiveFiltersCount() > 0 && (
                  <Badge variant="secondary" className="ml-1">
                    {getActiveFiltersCount()}
                  </Badge>
                )}
              </Button>

              {FILTERS.placeType.map((filter) => (
                <Badge
                  key={filter.id}
                  variant={selectedFilters.type === filter.id ? 'default' : 'outline'}
                  className="h-11 px-4 rounded-xl flex items-center gap-2 cursor-pointer transition-colors"
                  onClick={() => setSelectedFilters(prev => ({
                    ...prev,
                    type: prev.type === filter.id ? '' : filter.id
                  }))}
                >
                  {filter.icon}
                  <span>{filter.label}</span>
                </Badge>
              ))}

              <Badge
                variant={selectedFilters.status === 'open' ? 'default' : 'outline'}
                className="h-11 px-4 rounded-xl flex items-center gap-2 cursor-pointer transition-colors"
                onClick={() => setSelectedFilters(prev => ({
                  ...prev,
                  status: prev.status === 'open' ? '' : 'open'
                }))}
              >
                <Clock className="w-4 h-4" />
                <span>Open Now</span>
              </Badge>
            </div>
          </ScrollArea>
        </div>

        {/* Search Button */}
        <Button
          onClick={handleSearch}
          disabled={!location || isSearching}
          className="w-full h-14 md:h-12 mt-2 rounded-2xl md:rounded-xl bg-success-500 hover:bg-success-400 text-black font-medium text-base"
        >
          {isSearching ? (
            <Loader2 className="w-5 h-5 mr-2 animate-spin" />
          ) : (
            <Search className="w-5 h-5 mr-2" />
          )}
          Search
        </Button>

        {/* Mobile Filters Sheet */}
        <Sheet open={showFilters && window.innerWidth < 768} onOpenChange={setShowFilters}>
          <SheetContent side="bottom" className="h-[80vh] rounded-t-3xl">
            <SheetHeader className="px-2">
              <SheetTitle className="text-xl">Filters</SheetTitle>
            </SheetHeader>

            <ScrollArea className="h-full px-2 py-4">
              <div className="space-y-6">
                {Object.entries(FILTERS).map(([category, options]) => (
                  <div key={category} className="space-y-3">
                    <h3 className="text-base font-medium capitalize">{category}</h3>
                    <div className="grid grid-cols-2 gap-3">
                      {options.map((filter) => (
                        <Button
                          key={filter.id}
                          variant="outline"
                          className={`h-14 justify-start gap-3 rounded-2xl text-base ${
                            selectedFilters[category] === filter.id ?
                            'border-success-500 text-success-700 bg-success-50' : ''
                          }`}
                          onClick={() => setSelectedFilters(prev => ({
                            ...prev,
                            [category]: prev[category] === filter.id ? '' : filter.id
                          }))}
                        >
                          {filter.icon}
                          <span>{filter.label}</span>
                        </Button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>

            <SheetFooter className="px-2 py-4 border-t">
              <div className="flex w-full gap-3">
                <Button
                  variant="outline"
                  onClick={() => setSelectedFilters({})}
                  disabled={getActiveFiltersCount() === 0}
                  className="flex-1 h-14 rounded-2xl text-base"
                >
                  Clear All
                </Button>
                <Button
                  onClick={() => setShowFilters(false)}
                  className="flex-1 h-14 rounded-2xl bg-success-500 hover:bg-success-400 text-black text-base"
                >
                  Apply Filters
                </Button>
              </div>
            </SheetFooter>
          </SheetContent>
        </Sheet>

        {/* Desktop Filters Dialog */}
        <Dialog open={showFilters && window.innerWidth >= 768} onOpenChange={setShowFilters}>
          <DialogContent className="sm:max-w-[500px]">
            <DialogHeader>
              <DialogTitle className="flex items-center justify-between">
                Filters
                {getActiveFiltersCount() > 0 && (
                  <Badge variant="secondary">
                    {getActiveFiltersCount()} selected
                  </Badge>
                )}
              </DialogTitle>
            </DialogHeader>

            <div className="space-y-6">
              {Object.entries(FILTERS).map(([category, options]) => (
                <div key={category} className="space-y-3">
                  <h3 className="text-sm font-medium capitalize">{category}</h3>
                  <div className="grid grid-cols-2 gap-2">
                    {options.map((filter) => (
                      <Button
                        key={filter.id}
                        variant="outline"
                        className={`justify-start gap-2 rounded-xl ${
                          selectedFilters[category] === filter.id ?
                          'border-success-500 text-success-700 bg-success-50' : ''
                        }`}
                        onClick={() => setSelectedFilters(prev => ({
                          ...prev,
                          [category]: prev[category] === filter.id ? '' : filter.id
                        }))}
                      >
                        {filter.icon}
                        <span>{filter.label}</span>
                      </Button>
                    ))}
                  </div>
                </div>
              ))}

              <div className="flex justify-end gap-2 pt-4 border-t">
                <Button
                  variant="outline"
                  onClick={() => setSelectedFilters({})}
                  disabled={getActiveFiltersCount() === 0}
                  className="rounded-xl"
                >
                  Clear All
                </Button>
                <Button
                  onClick={() => setShowFilters(false)}
                  className="rounded-xl bg-success-500 hover:bg-success-400 text-black"
                >
                  Apply Filters
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}