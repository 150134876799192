import { useState } from "react";

export const useLocation = () => {
  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [cityName, setCityName] = useState<string>("");
  const [isLoadingLocation, setIsLoadingLocation] = useState<boolean>(false);

  const getLocation = async () => {
    setIsLoadingLocation(true);
    try {
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) =>
          navigator.geolocation.getCurrentPosition(resolve, reject)
      );
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });

      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      const data = await response.json();
      const fetchedCityName =
        data.address?.city || data.address?.town || "Your Location";
      setCityName(fetchedCityName);
    } catch (error) {
      console.error("Error fetching location:", error);
    } finally {
      setIsLoadingLocation(false);
    }
  };

  return { getLocation, isLoadingLocation, location, cityName };
};
