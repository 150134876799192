"use client";

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import {
  Search,
  Utensils,
  ThumbsUp,
  MapPin,
  Filter,
  Heart,
  Star,
  Clock,
  Share2,
} from 'lucide-react';

interface Feature {
  icon: React.ReactNode;
  text: string;
}

interface Step {
  id: number;
  title: string;
  description: string;
  icon: React.ReactNode;
  features: Feature[];
}

const steps: Step[] = [
  {
    id: 1,
    title: "Découvrez selon vos préférences",
    description: "Entrez simplement ce que vous recherchez dans notre barre de recherche, et nous vous fournirons des options sur mesure. Filtrez par cuisine, budget, et emplacement.",
    icon: <Search className="w-6 h-6" />,
    features: [
      { icon: <Filter className="w-4 h-4" />, text: "Filtrez par cuisine" },
      { icon: <MapPin className="w-4 h-4" />, text: "Trouvez des restaurants à proximité" },
      { icon: <Clock className="w-4 h-4" />, text: "Vérifiez les horaires d'ouverture" }
    ]
  },
  {
    id: 2,
    title: "Explorez les recommandations",
    description: "Découvrez des lieux sélectionnés basés sur les avis réels et les notes de notre communauté. Consultez les menus, les photos et les avis détaillés.",
    icon: <Utensils className="w-6 h-6" />,
    features: [
      { icon: <Star className="w-4 h-4" />, text: "Notes et avis vérifiés" },
      { icon: <Heart className="w-4 h-4" />, text: "Sauvegardez vos favoris" },
      { icon: <ThumbsUp className="w-4 h-4" />, text: "Partagez votre expérience" }
    ]
  },
  {
    id: 3,
    title: "Profitez de votre expérience",
    description: "Visitez le restaurant choisi et profitez d'une expérience culinaire parfaitement adaptée à vos goûts. Partagez votre avis pour aider la communauté.",
    icon: <ThumbsUp className="w-6 h-6" />,
    features: [
      { icon: <Star className="w-4 h-4" />, text: "Notez votre expérience" },
      { icon: <Heart className="w-4 h-4" />, text: "Créez votre collection" },
      { icon: <Share2 className="w-4 h-4" />, text: "Partagez vos découvertes" }
    ]
  }
];

const YouTubeEmbed: React.FC = () => {
  return (
    <div className="relative w-full aspect-video bg-muted rounded-lg overflow-hidden">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/DvL61UqMHTg?si=dLTIgIa-FhDBx6wb" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
    </div>
  );
};

const FeatureCard: React.FC<{ feature: Feature; delay: number }> = ({ feature, delay }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay }}
    className="flex items-center gap-3 p-3 rounded-lg bg-muted/50 hover:bg-muted/70 transition-colors group"
  >
    <motion.div
      className="text-primary transition-colors group-hover:text-primary/80"
      whileHover={{ scale: 1.1 }}
    >
      {feature.icon}
    </motion.div>
    <span className="text-sm">{feature.text}</span>
  </motion.div>
);

export default function HowItWorks() {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <section className="mx-auto max-w-7xl px-4 py-12">
      <motion.div
        className="text-center mb-12"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4">
          Comment fonctionne Douq ?
        </h2>
        <p className="text-muted-foreground max-w-2xl mx-auto">
          Découvrez les meilleurs endroits adaptés à vos préférences en quelques étapes simples
        </p>
      </motion.div>

      <div className="grid lg:grid-cols-2 gap-8">
        {/* Video Section */}
        <Card className="overflow-hidden lg:sticky lg:top-24 h-fit">
          <YouTubeEmbed />
        </Card>

        {/* Steps Accordion */}
        <div className="space-y-4">
          <Accordion
            type="single"
            defaultValue="1"
            className="space-y-4"
            onValueChange={(value) => setActiveStep(parseInt(value))}
          >
            <AnimatePresence>
              {steps.map((step) => (
                <AccordionItem
                  key={step.id}
                  value={step.id.toString()}
                  className={cn(
                    "border rounded-xl overflow-hidden bg-card transition-all duration-300",
                    "data-[state=open]:shadow-md",
                    "hover:border-primary/50"
                  )}
                >
                  <AccordionTrigger className="px-4 py-4 hover:no-underline">
                    <motion.div
                      className="flex items-center gap-4"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center">
                        {step.icon}
                      </div>
                      <div className="text-left">
                        <div className="font-semibold">{step.title}</div>
                      </div>
                    </motion.div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="px-4 pb-4 space-y-4">
                      <p className="text-muted-foreground">
                        {step.description}
                      </p>
                      <div className="grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-3">
                        {step.features.map((feature, index) => (
                          <FeatureCard
                            key={index}
                            feature={feature}
                            delay={index * 0.1}
                          />
                        ))}
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </AnimatePresence>
          </Accordion>
        </div>
      </div>
    </section>
  );
}