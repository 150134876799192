import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/accordion/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/autocomplete/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/avatar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/badge/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/breadcrumbs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/button/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/calendar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/card/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/checkbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/chip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/date-input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/date-picker/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/dropdown/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ResizablePanel"] */ "/vercel/path0/node_modules/@nextui-org/framer-utils/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/image/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/link/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/listbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/modal/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/navbar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/pagination/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/popover/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/progress/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/radio/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/ripple/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/scroll-shadow/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/select/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/skeleton/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/slider/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/snippet/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/switch/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/system/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/table/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/tabs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/tooltip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/user/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(website)/_components/how-it-works.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(website)/_components/restaurants-names.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(website)/_components/services.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(website)/_components/top-restaurants.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/search/global-search.tsx");
