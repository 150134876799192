import { City, Dish, GalleryResponse, Kitchen, Neighborhood, Place } from "@/types";

interface FetchPlacesParams {
  query?: string;
  latitude?: number;
  longitude?: number;
  radius?: number;
  place_type?: string;
  min_rating?: number;
  max_rating?: number;
  has_website?: boolean;
  has_email?: boolean;
  open_now?: boolean;
  city?: string;
  page?: number;
  per_page?: number;
}

interface FetchPlacesResponse {
  places: Place[],
  results: {
    cities: City[];
    neighborhoods: Neighborhood[];
    places: Place[];
    dishes: Dish[];
    kitchens: Kitchen[];
  };
  pagination: {
    current_page: number;
    total_pages: number;
    total_count: number;
  };
}


export interface SearchResponse {
  pagy: {
    current_page: number;
    total_pages: number;
    total_items: number;
  };
  places: Place[];
}

interface CacheItem<T> {
  data: T;
  timestamp: number;
}

const CACHE_DURATION = 5 * 60 * 1000;
const cache: { [key: string]: CacheItem<any> } = {};

/**
 * cache to avoid redundant API calls
 */
async function fetchWithCache<T>(url: string, options: RequestInit = {}): Promise<T | null> {
  const cacheKey = `${url}${JSON.stringify(options)}`;
  const cachedItem = cache[cacheKey];

  if (cachedItem && Date.now() - cachedItem.timestamp < CACHE_DURATION) {
    return cachedItem.data;
  }

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.status}`);
    }

    const data = await response.json();
    cache[cacheKey] = { data, timestamp: Date.now() };
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    return null;
  }
}

/**
 * Fetch list of places based on query params
 */
export const fetchPlaces = async (
  params: FetchPlacesParams
): Promise<FetchPlacesResponse | null> => {
  const url = new URL(`${process.env.NEXT_PUBLIC_BASE_URL_RUBY}/places`);

  Object.keys(params).forEach((key) => {
    const value = params[key as keyof FetchPlacesParams];
    if (value !== undefined) {
      url.searchParams.append(key, String(value));
    }
  });

  try {
    const response = await fetch(url.toString());

    if (!response.ok) {
      throw new Error("Failed to fetch places");
    }

    const data: FetchPlacesResponse = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching places:", error);
    return null;
  }
};

/**
 * Fetch details of a specific place by ID
 */
export const fetchPlaceById = async (id: string): Promise<Place | null> => {
  const url = `${process.env.NEXT_PUBLIC_BASE_URL_RUBY}/places/${encodeURIComponent(id)}`;

  return await fetchWithCache<Place>(url);
};

/**
 * Fetch gallery of a specific place by ID
 */
export const fetchPlaceGallery = async (id: string): Promise<GalleryResponse> => {
  const url = `${process.env.NEXT_PUBLIC_BASE_URL_RUBY}/places/${encodeURIComponent(id)}/gallery`;
  const response = await fetch(url);
  
  if (!response.ok) {
    throw new Error('Failed to fetch gallery');
  }
  
  return response.json();
};

/**
 * Fetch reviews of a specific place by ID
 */
export const fetchPlaceReviews = async (id: string): Promise<{ reviews: any[] } | null> => {
  const url = `${process.env.NEXT_PUBLIC_BASE_URL_RUBY}/places/${encodeURIComponent(id)}/reviews`;

  return await fetchWithCache<{ reviews: any[] }>(url);
};