import React from 'react';

interface StarRatingProps {
  rating: number;
  maxStars?: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating = 0, maxStars = 5 }) => {
  const roundedRating = Math.round(rating * 2) / 2;

  return (
    <div className="inline-flex items-center">
      <a
        href="#reviews"
        className="flex items-center space-x-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 rounded-md p-1"
        aria-label={`Rating: ${rating || 0} out of ${maxStars} stars. Click to read reviews.`}
      >
        {/* Render stars */}
        {Array.from({ length: maxStars }, (_, index) => {
          const starIndex = index + 1;
          return (
            <span
              key={starIndex}
              className={`star text-xl transition-colors duration-200 ease-in-out ${
                starIndex <= Math.floor(roundedRating) ? 'text-yellow-400' : 'text-gray-300'
              }`}
            >
              <svg
                className={`w-5 h-5 ${starIndex <= Math.floor(roundedRating) ? 'fill-current' : 'stroke-current'}`}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          );
        })}
        {/* Rating value */}
        <span className="ml-2 text-sm font-medium text-gray-600">
          {rating !== null ? rating.toFixed(1) : '-'}
        </span>
      </a>
    </div>
  );
};

export default StarRating;
